export default {

	/**
	 * API base URI
	 * 
	 * @type {String|null}
	 */
	api: null,

	/**
	 * Authentication token
	 *
	 * @type {String|null}
	 */
	auth: null,

	/**
	 * Cached inputs to the content data entry screen
	 *
	 * Enables e.g. automatic pre-selection of last used 
	 * values next time a new data entry is initiated.
	 *
	 * @type {Object}
	 */
	contentDataEntryCache: {},

	/**
	 * Content query object
	 * 
	 * @type {Object}
	 */
	contentQuery: {},

	/**
	 * Content queries object
	 *
	 * To replace `contentQuery` - multiple query objects 
	 * indexed by a unique ID to enable switching between.
	 * 
	 * @type {Object}
	 */
	contentQueries: {},

	/**
	 * Enable dark theme
	 * 
	 * @type {Boolean}
	 */
	dark: false,

	/**
	 * Drawer details
	 * 
	 * @type {Object}
	 */
	drawer: {

		/**
		 * Open?
		 * 
		 * @type {Boolean}
		 */
		open: false,

		/**
		 * Visible?
		 * 
		 * @type {Boolean}
		 */
		visible: false

	},

	/**
	 * F12 visible
	 * 
	 * @type {Boolean}
	 */
	f12: false,

	/**
	 * Route key
	 * 
	 * @type {Integer}
	 */
	rkey: 0,

	/**
	 * S3 media storage
	 * 
	 * @type {Boolean}
	 */
	s3Media: true,

	/**
	 * Statcounter enabled
	 *
	 * @type {Boolean}
	 */
	statcounter: true,

	/**
	 * Stream subscriptions
	 *
	 * @type {Array|null}
	 */
	stream: null,

	/**
	 * Table page size
	 * 
	 * @type {Integer}
	 */
	tablePageSize: 50,

	/**
	 * Authenticated user object
	 * 
	 * @type {Object}
	 */
	user: {

		/**
		 * User's UUID
		 * 
		 * @type {String|null}
		 */
		Uuid: null,

		/**
		 * User's admin status
		 * 
		 * @type {Boolean}
		 */
		Admin: false,

		/**
		 * User's access token
		 *
		 * @type {String|null}
		 */
		AccessToken: null,

		/**
		 * Email address
		 * 
		 * @type {String|null}
		 */
		Email: null,

		/**
		 * Name
		 * 
		 * @type {String|null}
		 */
		Name: null,

		/**
		 * Organisation
		 * 
		 * @type {Object|null}
		 */
		Org: null,

		/**
		 * Locale setting
		 * 
		 * @type {Object}
		 */
		Locale: {

			/**
			 * Locale ID
			 * 
			 * @type {Integer}
			 */
			Id: null,

			/**
			 * Date display format (`strftime`-format)
			 * 
			 * @type {String}
			 */
			DateDisplayFormat: null

		},

		/**
		 * Assigned region
		 * 
		 * @type {Object|null}
		 */
		AssignedRegion: {

			/**
			 * Region UUID
			 *
			 * @type {String|null}
			 */
			Uuid: null,

			/**
			 * Region Name
			 * 
			 * @type {String|null}
			 */
			Name: null

		},

		/**
		 * Power BI access enabled
		 * 
		 * @type {Boolean}
		 */
		PowerBiAccess: null,

		/**
		 * Allowed to view URLs
		 * 
		 * @type {Boolean}
		 */
		UrlsVisible: null


	},

	/**
	 * Cookie Banner
	 * 
	 * @type {Boolean|null}
	 */
	cookies: null
};
